<template>
  <v-app class="">
    <!-- TOP BAR -->
    <v-app-bar app dense class="elevation-1 white hidden-md-and-up">
      <v-card @click="reload()" class="transparent pa-2" flat>
        <router-link to="login" style="cursor: pointer">
          <v-img
            contain
            src="@/assets/Tlogo.png"
            width="120"
            height="auto"
          ></v-img>
        </router-link>
      </v-card>
    </v-app-bar>
    <v-layout row wrap class="mt-3 grey lighten-3">
      <v-flex xs12 sm12 md8 lg8 class="px-10 mb-6" style="margin-top: 3%">
        <router-view></router-view>
      </v-flex>
      <v-flex xs12 sm12 md4 lg4 class="hidden-sm-and-down">
        <v-img
          contain
          src="@/assets/landing.jpg"
          width="100%"
          class="img full-height"
        />
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
// import Login from "@/components/login/Login";
export default {
  components: {
    // Login,
  },
  data() {
    return {
      isSelected: true,
    };
  },
  watch: {
    $route() {
      this.titleChange();
    },
  },
  mounted() {
    localStorage.removeItem("vuex");
    window.addEventListener("online", this.checkConnection);
    window.addEventListener("offline", this.checkConnection);
    this.titleChange();
  },
  methods: {
    checkConnection() {
      this.snackOnline = navigator.onLine ? false : true;
    },
    titleChange() {
      if (this.$route.name == "ForgotPassword") {
        this.title = "RESET PASSWORD";
      } else {
        this.title = "SIGN IN";
      }
    },
    toSwitch(menu) {
      switch (menu) {
        case "login":
          this.isSelected = true;
          this.$router.push("/login");
          break;
        case "register":
          this.isSelected = false;
          this.$router.push("/login/register");
          break;
      }
    },
  },
};
</script>
<style scoped>
.full-height {
  height: 100%;
  background-image: linear-gradient(to bottom, #f0af7a, #62d2fa);
}
/* .img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30%;
  margin-bottom: 30%;
} */
</style>
